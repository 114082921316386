<template>
  <div class="container">
    <a-button
      @click="$router.back()"
      style="border-radius: 20px; background-image: linear-gradient(to right, #1D2671 , #830051); color: white;"
      ><LeftOutlined /> Back</a-button
    >
    <div class="card mt-3" style="border-radius: 20px;">
      <div
        class="w-100 bg-primary d-flex align-items-center justify-content-center"
        style="border-radius: 20px 20px 0 0;background-image: linear-gradient(to right, #1D2671 , #830051);"
      >
        <p class="my-4 text-white" :class="$style['status-title']">Status</p>
      </div>
      <div
        class="px-5 pt-5"
        :style="{
          display: settings.isTabletView ? 'flex' : '',
          flexDirection: settings.isTabletView ? 'column' : '',
          alignItems: settings.isTabletView ? 'center' : '',
          justifyContent: settings.isTabletView ? 'center' : '',
        }"
      >
        <a-steps
          :current="step"
          size="small"
          :direction="settings.isTabletView ? 'vertical' : 'horizontal'"
          :style="{
            width: settings.isTabletView ? '200px' : '',
          }"
        >
          <a-step title="Persetujuan Pasien" />
          <a-step title="Unggah Hasil Tes" />
          <a-step
            title="Unggah Asuransi"
            v-if="
              (dataPatient &&
                dataPatient?.programs.length &&
                dataPatient?.programs[0]?.category &&
                dataPatient?.programs[0]?.category.toLowerCase() == 'aurora') ||
                program == 'aurora'
            "
          />
          <a-step title="Konfirmasi Dokter" />
          <a-step title="Unggah Resep Dokter" />
          <a-step title="Verifikasi" />
        </a-steps>
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center my-5 my-sm-5">
        <div style="width: 50px; height: 50px;" class="my-3" :class="$style['icons-list']">
          <ExclamationCircleTwoTone two-tone-color="#eb2f96" :style="{ fontSize: '50px' }" />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center "
          v-if="
            dataPatient &&
              dataPatient?.programs.length &&
              dataPatient?.programs[0]?.category &&
              dataPatient?.programs[0]?.category.toLowerCase() == 'aurora'
          "
        >
          <p v-if="step === 0" class="m-0 text-center mb-4 mb-sm-3">
            Anda belum memasukkan dokumen persetujuan, silahkan isi dokumen.
          </p>
          <p v-else-if="step === 1" class="m-0 text-center mb-4 mb-sm-3">
            Hasil tes anda kosong, apakah anda ingin melakukan tes?
          </p>
          <p v-else-if="step === 3" class="m-0 text-center mb-4 mb-sm-3">
            Silahkan hubungi dokter anda untuk mendapatkan kode verifikasi. Jika sudah memiliki kode
            verifikasi, anda dapat melanjutkan proses selanjutnya.
          </p>
          <p v-else-if="step === 4" class="m-0 text-center mb-4 mb-sm-3">
            Anda belum memasukkan resep, silahkan unggah resep dokter rujukan untuk anda.
          </p>
          <p v-else-if="step === 5" class="m-0 text-center mb-4 mb-sm-3">
            Dokumen anda sedang ditinjau oleh verifikator. Silahkan menunggu hingga dokumen anda
            disetujui atau ditolak.
          </p>
          <a-button
            v-if="step === 0"
            type="primary"
            html-type="submit"
            :class="$style['custom-button-yes']"
            :loading="loading"
            @click="handleStepRoute"
          >
            <strong>Mengisi Dokumen</strong>
          </a-button>

          <div v-if="step === 1">
            <a-button
              type="primary"
              html-type="submit"
              class="mr-2"
              :class="$style['custom-button-yes']"
              :loading="loading"
              @click="handleStepRoute"
            >
              <strong>Ikuti Tes Lab</strong>
            </a-button>
            <a-button
              type="primary"
              html-type="submit"
              :class="$style['custom-button-yes']"
              :loading="loading"
              @click="handleStepRoute"
            >
              <strong>Unggah hasil tes</strong>
            </a-button>
          </div>
          <a-button
            v-if="step === 2"
            type="primary"
            html-type="submit"
            :class="$style['custom-button-yes']"
            :loading="loading"
            @click="handleStepRoute"
          >
            <strong>Unggah Asuransi</strong>
          </a-button>
          <a-button
            v-if="step === 3"
            type="primary"
            html-type="submit"
            :class="$style['custom-button-yes']"
            :loading="loading"
            @click="handleStepRoute"
          >
            <strong>Konfirmasi Dokter</strong>
          </a-button>
          <a-button
            v-if="step === 4"
            type="primary"
            html-type="submit"
            :class="$style['custom-button-yes']"
            :loading="loading"
            @click="handleStepRoute"
          >
            <strong>Unggah Resep</strong>
          </a-button>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center px-3 px-sm-0"
          v-else
        >
          <p v-if="step === 0" class="m-0 text-center mb-4 mb-sm-3">
            Anda belum memasukkan dokumen persetujuan, silahkan isi dokumen.
          </p>
          <p v-else-if="step === 1" class="m-0 text-center mb-4 mb-sm-3">
            Hasil tes anda kosong, apakah anda ingin melakukan tes?
          </p>
          <p v-else-if="step === 2" class="m-0 text-center mb-4 mb-sm-3">
            Silahkan hubungi dokter anda untuk mendapatkan kode verifikasi. Jika sudah memiliki kode
            verifikasi, anda dapat melanjutkan proses selanjutnya.
          </p>
          <p v-else-if="step === 3" class="m-0 text-center mb-4 mb-sm-3">
            Anda belum memasukkan resep, silahkan unggah resep dokter rujukan untuk anda.
          </p>
          <p v-else-if="step === 4" class="m-0 text-center mb-4 mb-sm-3">
            Dokumen anda sedang ditinjau oleh verifikator. Silahkan menunggu hingga dokumen anda
            disetujui atau ditolak.
          </p>
          <a-button
            v-if="step === 0"
            type="primary"
            html-type="submit"
            :class="$style['custom-button-yes']"
            :loading="loading"
            @click="handleStepRoute"
          >
            <strong>Mengisi Dokumen</strong>
          </a-button>

          <div v-if="step === 1">
            <div class="row text-center">
              <div class="col-12 col-md-6">
                <a-button
                  type="primary"
                  html-type="submit"
                  :class="$style['custom-button-yes']"
                  :loading="loading"
                  @click="handleStepRoute"
                >
                  <strong>Ikuti Tes Lab</strong>
                </a-button>
              </div>
              <div class="col-12 col-md-6 mt-2 mt-sm-0">
                <a-button
                  type="primary"
                  html-type="submit"
                  :class="$style['custom-button-yes']"
                  :loading="loading"
                  @click="handleStepRoute"
                >
                  <strong>Unggah hasil tes</strong>
                </a-button>
              </div>
            </div>
          </div>
          <a-button
            v-if="step === 2"
            type="primary"
            html-type="submit"
            :class="$style['custom-button-yes']"
            :loading="loading"
            @click="handleStepRoute"
            class="mb-5"
          >
            <strong>Konfirmasi Dokter</strong>
          </a-button>
          <a-button
            v-if="step === 3"
            type="primary"
            html-type="submit"
            :class="$style['custom-button-yes']"
            :loading="loading"
            @click="handleStepRoute"
          >
            <strong>Unggah Resep</strong>
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  onMounted,
  onBeforeUnmount,
  ref,
  reactive,
  createVNode,
  toRef,
  toRefs,
  watch,
  computed,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { baseURL } from '@/services/axios'
import { useStore } from 'vuex'
import {
  CheckOutlined,
  ToolOutlined,
  PhoneOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  InfoCircleOutlined,
  HomeOutlined,
  ContactsOutlined,
  ExclamationCircleTwoTone,
  LeftOutlined,
} from '@ant-design/icons-vue'
import { Modal, notification, message } from 'ant-design-vue'
import { useForm } from '@ant-design-vue/use'
import axios from 'axios'
import PersetujuanVue from './proses/persetujuan.vue'
import { getPatientProgram, getSurveyResult } from '../../services/axios/pulih'

export default {
  components: {
    PersetujuanVue,
    ExclamationCircleTwoTone,
    LeftOutlined,
  },
  setup() {
    /* eslint-disable */
    const route = useRoute()
    const router = useRouter()
    let checked = ref(false)
    let step = ref(0)
    const store = useStore()
    let settings = computed(() => store.getters.settings)
    const program = computed(() => store.state.program)

    const data = computed(() => store.state.dataHasilTes)

    const handleStepRoute = () => {
      console.log(dataPatient.programs)
      if (
        dataPatient.value.programs.length &&
        dataPatient.value.programs[0]?.category &&
        dataPatient.value.programs[0].category.toLowerCase() === 'aurora'
      ) {
        switch (step.value) {
          case 0:
            router.push(`/program/persetujuan`)
            break
          case 1:
            router.push(`/program/tes-list`)
            break
          case 2:
            router.push(`/unggah-asuransi`)
            break
          case 3:
            router.push(`/program/konfirmasi-dokter`)
            break
          case 4:
            router.push(`/program/unggah-resep-dokter`)
            break
          default:
            break
        }
      } else {
        switch (step.value) {
          case 0:
            router.push(`/program/persetujuan`)
            break
          case 1:
            router.push(`/program/tes-list`)
            break
          case 2:
            router.push(`/program/konfirmasi-dokter`)
            break
          case 3:
            router.push(`/program/unggah-resep-dokter`)
            break
          default:
            break
        }
      }
    }

    const goDashboard = () => {
      router.push(`/dashboard`)
    }
    const dataPatient = ref({ programs: [] })
    onMounted(async () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const res = await getPatientProgram(userData.user.code)
      // const responseSurvey = await getSurveyResult(res.id)
      // console.log(responseSurvey, 'iniresponsurvey')
      // if (!data && res.programs[0].checkPoint === 4) {
      //   router.push('/survey')
      // }

      console.log('inisurveyResult', data)
      dataPatient.value = res
      console.log(dataPatient.value, 'inidataPasienpakecode')
      const program = store.state.program
      const programType = store.state.programType
      if (!dataPatient.value.programs.length) {
        if (!program || !programType) {
          router.push('/dashboard')
        }
      }
      // console.log(dataPatient.value.programs[0].category, 'iniprogramnyacategory')
      // step.value = res.programs[0].checkPoint - 1
      // step.value = 2
      // step.value = 3
      if (dataPatient.value.programs.length) {
        if (dataPatient.value.programs[0].checkPoint === 5) {
          router.push('/dashboard')
        }
        if (dataPatient.value.programs[0].category === 'aurora') {
          if (res.programs.length) {
            if (res.programs.length && !res.programs[0].testLab) {
              step.value = 1
            }
            if (res.programs.length && res.programs[0].testLab && !res.programs[0].insurance) {
              step.value = 2
            }
            if (res.programs.length && res.programs[0].testLab.id && res.programs[0].insurance.id) {
              step.value = 3
            }
            if (res.programs[0].checkPoint === 3) {
              step.value = 4
            }
            if (res.programs[0].checkPoint === 4) {
              step.value = 5
            }
          } else {
            step.value = 0
          }
        } else {
          if (res.programs.length) {
            if (res.programs.length && !res.programs[0].testLab) {
              step.value = 1
            }
            if (res.programs.length && res.programs[0].testLab) {
              step.value = 2
            }
            // if (res.programs.length && res.programs[0].testLab && res.programs[0].testLab.id) {
            //   step.value = 2
            // }
            if (res.programs[0].checkPoint === 3) {
              step.value = 3
            }
            if (res.programs[0].checkPoint === 4) {
              step.value = 4
            }
          } else {
            step.value = 0
          }
        }
      }
      console.log(step.value, 'inistepnya')
      // if (res.programs.length) {
      //   if (res.programs.length && !res.testLabs.length) {
      //     step.value = 1
      //   } else {
      //     step.value = 2
      //   }
      //   if (res.programs[0].checkPoint === 3) {
      //     step.value = 3
      //   }
      //   if (res.programs[0].checkPoint === 4) {
      //     step.value = 4
      //   }
      // } else {
      //   step.value = 0
      // }
    })
    onBeforeUnmount(() => {})

    return {
      program,
      settings,
      dataPatient,
      goDashboard,
      checked,
      step,
      handleStepRoute,
    }
  },
}
</script>

<style lang="scss" module>
:global(.ant-steps-item-process .ant-steps-item-icon) {
  background: #c2d52f !important;
  border-color: #c2d52f !important;
}
:global(.ant-steps-item-finish .ant-steps-item-icon) {
  // background: #c2d52f !important;
  border-color: #c2d52f !important;
}

:global(.ant-steps-icon > span > svg) {
  fill: #c2d52f !important;
}
:global(.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after) {
  background-color: #c2d52f !important;
}
:global(.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after) {
  background-color: #c2d52f !important;
}
:global(.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title) {
  color: #c2d52f !important;
}
:global(.ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title) {
  color: #94a517 !important;
}

.status-title {
  font-size: 24px;
}
.icons-list :deep(.anticon) {
  margin-right: 6px;
  font-size: 50px;
}

.custom-button-yes {
  // width: 100%;
  height: 50px !important;
  font-weight: 500;
  font-size: 15px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #eda702, #c76b1e) !important;
  border: 1px solid #eda702 !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #eda702 !important;
    background: transparent !important;
  }
}
</style>
